<template>
  <ion-page>
    <ion-tabs>
        
        <ion-router-outlet></ion-router-outlet>

        <ion-tab-bar slot="bottom" :class="'mainRoutes ' + (toggleMenu ? 'open' : 'closed')">
            <div class="menuToggle" v-if="hasRole('60d58e3f7975c685d0e35ca7')">
                <div class="menuToggleButton" @click="toggleMenu = !toggleMenu" aria-label="Admin">
                    <ion-icon :src="'/assets/icon/i-menu.svg'" />
                    <ion-label>Admin</ion-label>
                </div>
            </div>
            <ion-tab-button 
                v-for="route in routes.filter((x: any) => hasRole(x.meta.role))" 
                :key="route.path" 
                :tab="route.path" 
                :href="route.path" 
                :class="route.meta.customClasses" 
                @click="checkLinkType(route.path)" 
                :disabled="(route.meta.disabledOnNoRafter == true) && !currentRafter"
                :data-after="route.path == '/todo' ? getTodoCount() : ''"
            >
                <ion-icon :src="'/assets/icon/' + route.meta.icon + '.svg'" />
                <ion-label v-if="!route.meta.iconOnly">{{ route.name }}</ion-label>
            </ion-tab-button>
        </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script lang="ts">
    import { IonPage, IonTabs, IonRouterOutlet, IonTabBar, IonTabButton, IonLabel, IonIcon, modalController } from '@ionic/vue';
    import { useStorage } from "@/composables/useStorage";
    import { useRouter } from 'vue-router';
    import { CheckSheet, Medication, Person, Rafter, WeeklyTask } from "@/types";
    import { defineComponent, inject, onBeforeMount, ref } from 'vue';
    import ToDoModal from '../components/ToDoModal.vue';
    import SwitchLocationModal from '../components/SwitchLocationModal.vue';
import { checksheetFunctions } from '@/composables/checksheetFunctions';
import dayjs from 'dayjs';
import { GlobalCheckSheetKey, GlobalRafterKey, GlobalUserKey } from '@/composables/globals';

    export default defineComponent({
        components: { IonPage, IonTabs, IonRouterOutlet, IonTabBar, IonTabButton, IonLabel, IonIcon },
        setup() {
            const toggleMenu = ref(false);

            const GlobalRafter = inject(GlobalRafterKey, undefined);
            const globalCheckSheet = inject(GlobalCheckSheetKey, undefined);
            const globalUser = inject(GlobalUserKey, undefined);

            const currentRafter = GlobalRafter?.getGlobalRafter();

            const router = useRouter();
            const routes = router.getRoutes().filter(x => x.name);

            router.beforeEach(async (to, from) => {
                if( to.meta.toggleMenu ){
                    toggleMenu.value = !toggleMenu.value;
                }
            })

            const getTodoCount = () => {
                if(globalCheckSheet?.value){
                    let counter = 0;
                    if( globalCheckSheet.value.weeklyTasks ){
                        counter += globalCheckSheet.value.weeklyTasks.filter( (weeklyTask: WeeklyTask) => 
                                        dayjs(weeklyTask.created).isBefore(dayjs().add(1, 'day')) && !weeklyTask.person
                                    ).length;

                    }
                    if( globalCheckSheet.value.medications ){
                        counter += globalCheckSheet.value.medications.filter( (medication: Medication) => {
                                    if( dayjs(medication.medicationDateNominal).isBefore(dayjs().add(1, 'day')) ){
                                        if (!medication.medicationDoses || medication.medicationDoses.length == 0) {
                                            return true;
                                        }
                                    } 
                                    return false;
                                }).length;
                    }
                    return counter > 0 ? counter : '';
                }
                return "";
            };

            return {
                routes,
                globalUser,
                currentRafter,getTodoCount,
                toggleMenu,
            }
        },
        methods: {
            hasRole(role: string | undefined){
                if( !role ) return false;
                if(role.length == 0) return true;
                const find = this.globalUser?.roles?.find( r => {
                    return role.indexOf(r as string) > -1;
                });
                return find !== undefined;
            },
            async checkLinkType(path: string){
                if(path == '/todo'){
                    const position = document.getElementById('tab-button-/todo')?.getBoundingClientRect();
                    const modal = await modalController
                        .create({
                            component: ToDoModal,
                            cssClass: 'pd_todo_modal',
                            showBackdrop: false,
                            componentProps: {
                            },
                        });
                    if(position){
                        modal.style.paddingLeft = position?.x.toFixed() + 'px';
                        modal.style.paddingBottom = ((window.innerHeight - position.y + 13).toFixed()) + 'px';
                    }

                    modal.onDidDismiss().then((data : any) => {
                        if(data?.data){
                            console.log(data);
                        }
                    });
                    return modal.present();
                }
                else if(path == '/switch'){
                    const position = document.getElementById('tab-button-/switch')?.getBoundingClientRect();
                    const modal = await modalController
                        .create({
                            component: SwitchLocationModal,
                            cssClass: 'pd_switchlocation_modal',
                            showBackdrop: false,
                            componentProps: {
                            },
                        });
                    if(position){
                        modal.style.paddingBottom = ((window.innerHeight - position.y + 13).toFixed()) + 'px';
                    }

                    modal.onDidDismiss().then(async (data : any) => {
                        if(data?.data){
                            const { setLocalStorage } = await useStorage();
                            const { findRafter, findExistingCheckSheet, findChecksheetTemplate } = checksheetFunctions();
                            await setLocalStorage('currentLocation', JSON.parse(JSON.stringify(data.data)));

                            const currentRafter = await findRafter(data.data);
                            if( currentRafter ){
                                await findExistingCheckSheet(currentRafter);
                            }
                            await findChecksheetTemplate(data.data);

                            window.location.reload();
                        }
                    });
                    return modal.present();
                }
            },
            
        }
    });
</script>