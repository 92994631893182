import { createApp, ref } from 'vue'
import App from './App.vue'
import router from './router';
import './registerServiceWorker';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Custom Styles */
import './theme/custom.min.css';
import VueApexCharts from "vue3-apexcharts";
import { createAnimation } from '@ionic/vue';
import { CheckSheet, Rafter } from './types';
import { GlobalRafterKey, loadGlobalValues } from './composables/globals';
import { useStorage } from './composables/useStorage';

const animationBuilder = (baseEl: any, opts: any) => {
    const enteringAnimation = createAnimation()
      .addElement(opts.enteringEl)
      .fromTo('opacity', 0, 1)
      .duration(250);
    
    const leavingAnimation = createAnimation()
      .addElement(opts.leavingEl)
      .fromTo('opacity', 1, 0)
      .duration(250);
    
    const animation = createAnimation()
      .addAnimation(enteringAnimation)
      .addAnimation(leavingAnimation);
    
    return animation;
  };

const app = createApp(App)

  .use(IonicVue,{
    swipeBackEnabled: true,
    navAnimation: animationBuilder
  })
  .use(router);
app.use(VueApexCharts);

const routerKey = ref<number>(0);
app.provide('routerKey', routerKey);
app.provide('incrementRouterKey', () => routerKey.value++);


await loadGlobalValues(app);


// const globalRafter = ref<Rafter | undefined>(await getLocalStorage('currentRafter'));
// const globalCustomRafter = ref<Rafter | undefined>(await getLocalStorage('currentCustomRafter'));
// function updateGlobalRafter(rafter: Rafter | undefined, custom: boolean) {
//     console.log('update global rafter');
//     if(custom)
//         globalCustomRafter.value = rafter;
//     else
//         globalRafter.value = rafter;
//     console.log(globalRafter.value);
// }
// console.log('global');
// console.log(globalRafter.value);
// console.log(globalLocation.value);
// app.provide(GlobalRafterKey, { globalRafter, globalCustomRafter, updateGlobalRafter });


router.isReady().then(() => {
  app.mount('#app');
});