
import { restClient } from './restClient';
import { useStorage } from './useStorage';
import { CheckSheetEntry, Medication, SyncItem, UserFile, WeeklyTask } from "@/types";
import dayjs from 'dayjs';
import { ObjectId } from 'bson';
import { onUpdated } from 'vue';

export async function syncData() {

    const getOnlineData = async (model: string) => {
        const { setLocalStorage } = await useStorage();
        const { loadData } = restClient();

        await setLocalStorage('syncingData', true);

        const data = await loadData(model, 0, -1);
        await setLocalStorage(model, data);

        await setLocalStorage('syncingData', false);
        await setLocalStorage('lastSyncCheck', new Date());
 
        return data;
    }

    const queueUpload = async (type: string, collection: string, itemid: string|undefined, field: string, value: any, key: any = undefined, stable: any = undefined, person: any = undefined, created: any = undefined) => {
        
        if(type == 'u' && !itemid) return;

        const upload = {
            'id': new ObjectId().toString(),
            'collection': collection, 
            'itemid': itemid, 
            'field': field, 
            'value': JSON.stringify(value), 
            'key': key, 
            'stable': stable, 
            'person': person, 
            'created': created,
            'type': type,
            'modtime': new Date()
        } as SyncItem;
        const { getLocalStorage, setLocalStorage } = await useStorage();

        let uploadQueue = await getLocalStorage('uploadQueue');
        if( !uploadQueue ){
            uploadQueue = [];
            uploadQueue.push(upload);
        }
        uploadQueue.push(upload);
        await setLocalStorage('uploadQueue', uploadQueue);
        
        await applyUpdate(upload);
    }

    const startUploading = async () => {
        const { getLocalStorage, setLocalStorage } = await useStorage();
        const { updateItem, createItem, deleteItem } = restClient();


        setInterval( async () => { 
            let uploadQueue = await getLocalStorage('uploadQueue');
            const checkPushInProgress = await getLocalStorage('pushInProgress');
            if( !checkPushInProgress && navigator.onLine && uploadQueue && uploadQueue.length > 0){
                uploadQueue = await getLocalStorage('uploadQueue');
                await setLocalStorage('pushInProgress', uploadQueue);
                const pushInProgress = await getLocalStorage('pushInProgress');
                await setLocalStorage('uploadQueue', []);

                pushInProgress.forEach( async (item: any) => {
                    item.modtime = new Date();
                    switch(item.type){
                        case 'u':{
                            await updateItem(item);
                        } break;
                        case 'i':{
                            if((item.collection == 'CheckSheet' && (item.field == 'Entries' || item.field == 'Medications' || item.field == 'WeeklyTasks')) || (item.collection == 'Rafter' && item.field == 'Files')){
                                await updateItem(item);
                            }
                            else{
                                await createItem(item.collection, item);
                            }
                        } break;
                        case 'd':{
                            if((item.collection == 'CheckSheet' && item.field == 'Medications') || (item.collection == 'Rafter' && item.field == 'Files')){
                                await updateItem(item);
                            }
                            else{
                                await deleteItem(item);
                            }
                        } break;
                    }
                });

                await setLocalStorage('pushInProgress', null);
            }
        }, 1000);
    }

    const watchUpdates = async ( modifiedCollections: any[], globalSync: SyncItem[]) => {
        
        const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
        (async function loop(modifiedCollections: any[]) {

            if( globalSync.length == 0){
                const { getLocalStorage, setLocalStorage } = await useStorage();
                const { checkForUpdates } = restClient();

                const lastCheck = (await getLocalStorage('lastSyncCheck')) ?? new Date();
                const newCheck = new Date();
                console.log('lastcheck: ' + lastCheck);

                console.log('Start checkForUpdates');
                const updates = await checkForUpdates(lastCheck);
                console.log('End checkForUpdates, found: ' + updates?.length);

                if(updates && updates.length > 0){
                    globalSync.push(...updates);

                    console.log('Start applying updates');
                    while( globalSync.length > 0 ){
                        console.log(globalSync.length + ' updates remaining');
                        const item = globalSync[0];
                        const appliedUpdates = await getLocalStorage('syncItems');
                        console.log('Got syncItmes, last: ' + appliedUpdates?.at(-1));
                        if( !appliedUpdates || appliedUpdates.indexOf(item.id) == -1){
                            console.log('Applying update ' + item.id);
                            await applyUpdate(item, modifiedCollections);
                            console.log('Update ' + item.id + ' applied');
                        }
                        else{
                            console.log('Update ' + item.id + ' already applied');
                        }
                        globalSync.splice(0,1);
                    }
                    await setLocalStorage('lastSyncCheck', newCheck);
                    console.log('End applying updates');
                }

                delay(10000).then(() => {
                    loop(modifiedCollections);
                });
            }
        })(modifiedCollections);
    }

    const applyUpdate = async (item: SyncItem, modifiedCollections: any[] = []) =>{
        try{
            if( !item.id ){
                console.log('item.id undefined');
                console.log(item);
            }
            const { getLocalStorage, setLocalStorage } = await useStorage();
            const data = await getLocalStorage(item.collection as string);
            switch(item.type){
                case 'u':{
                    const document = data.find( (elem: any) => elem.id == item.itemid);
                    if(item.collection == 'CheckSheet'){
                        if(item.field == 'Entries'){
                            const entry = document.entries.find( (elem: CheckSheetEntry) => elem.key == item.key && elem.stable == item.stable && dayjs(elem.created).isSame(item.created, 'day'));
                            if( !entry ){
                                console.log('not entry');
                                console.log(item);
                            }
                            if( entry ){
                                entry.value = JSON.parse(item.value);
                                entry.person = item.person;
                                entry.modified = item.modtime;
                                
                                modifiedCollections.push({type: 'CheckSheetEntry', value: entry, documentId: item.itemid});
                            }
                        }
                        else if(item.field == 'Medications'){
                            const index = document.medications.findIndex( (elem: Medication) => elem.id == item.key);
                            document.medications[index] = JSON.parse(item.value);
                            modifiedCollections.push({type: 'Medications', value: document.medications[index], documentId: item.itemid});
                        }
                        else if(item.field == 'WeeklyTasks'){
                            const index = document.weeklyTasks.findIndex( (elem: WeeklyTask) => elem.id == item.key);
                            document.weeklyTasks[index] = JSON.parse(item.value);

                            modifiedCollections.push({type: 'WeeklyTasks', value: document.weeklyTasks[index], documentId: item.itemid});
                        }
                        else{
                            if( item.field ){
                                const field = item.field?.substring(0, 1).toLowerCase() + item.field?.substring(1);
                                document[field] = JSON.parse(item.value);
                            }
                        }
                        const currentCheckSheet = await getLocalStorage('currentCheckSheet');
                        if(currentCheckSheet && currentCheckSheet.id == document.id){
                            await setLocalStorage('currentCheckSheet', JSON.parse(JSON.stringify(document)));
                        }
                    }
                    else if(item.collection == 'Rafter'){
                        if(item.field == 'Files'){
                            const value = JSON.parse(item.value);
                            const entry = document.files.find( (elem: UserFile) => elem.id == value.id);
                            if( entry ){
                                entry.name = value.name;
                                entry.comment = value.comment;
                                entry.tags = value.tags;
                                modifiedCollections.push({type: 'UserFile', value: value, documentId: item.itemid});
                            }
                            else{
                                if( !document.files ) document.files = [];
                                const entry = document.files.find( (elem: UserFile) => elem.id == JSON.parse(item.value).id);
                                if( !entry ){
                                    document.files.push(JSON.parse(item.value));
                                }
                            }
                        }
                        else{
                            if( item.field ){
                                const field = item.field?.substring(0, 1).toLowerCase() + item.field?.substring(1);
                                document[field] = JSON.parse(item.value);
                                modifiedCollections.push({type: item.collection, value: item.itemid, documentId: item.itemid});
                            }
                        }
                        const currentRafter = await getLocalStorage('currentRafter');
                        if(currentRafter && currentRafter.id == document.id){
                            await setLocalStorage('currentRafter', document);
                        }
                        const currentCustomRafter = await getLocalStorage('currentCustomRafter');
                        if(currentCustomRafter && currentCustomRafter.id == document.id){
                            await setLocalStorage('currentCustomRafter', document);
                        }
                    }
                    else if(item.collection == "Location"){
                        if( item.field ){
                            const field = item.field?.substring(0, 1).toLowerCase() + item.field?.substring(1);
                            document[field] = JSON.parse(item.value);
                        }
                        const currentLocation = await getLocalStorage('currentLocation');
                        if(currentLocation && currentLocation.id == document.id){
                            await setLocalStorage('currentLocation', JSON.parse(JSON.stringify(document)));
                            modifiedCollections.push({type: item.collection, value: document, documentId: item.itemid});
                        }
                        else
                            modifiedCollections.push({type: item.collection, value: item.itemid, documentId: item.itemid});
                    }
                    else{
                        if( item.field ){
                            const field = item.field?.substring(0, 1).toLowerCase() + item.field?.substring(1);
                            document[field] = JSON.parse(item.value);

                            modifiedCollections.push({type: item.collection, value: item.itemid, documentId: item.itemid});
                        }
                    }
                } break;
                case 'i':{
                    if(item.collection == 'CheckSheet'){
                        let updateCurrentCheckSheet = true;
                        const document = data.find( (elem: any) => elem.id == item.itemid);
                        if( item.field == 'WeeklyTasks' ){
                            document.weeklyTasks.push(JSON.parse(item.value));
                        }
                        else if( item.field == 'Entries' ){
                            document.entries.push(...JSON.parse(item.value));
                        }
                        else if( item.field == 'Medications' ){
                            document.medications.push(JSON.parse(item.value));
                            modifiedCollections.push({type: 'Medications', value: JSON.parse(item.value), documentId: item.itemid});
                        }
                        else{
                            data.push(JSON.parse(item.value));
                            updateCurrentCheckSheet = false;
                        }

                        if( updateCurrentCheckSheet ){
                            const currentCheckSheet = await getLocalStorage('currentCheckSheet');
                            if(currentCheckSheet && currentCheckSheet.id == item.itemid){
                                await setLocalStorage('currentCheckSheet', JSON.parse(JSON.stringify(document)));
                            }
                        }
                    }
                    else if( item.collection == 'Rafter' && item.field == 'Files'){
                        const document = data.find( (elem: any) => elem.id == item.itemid);
                        if( !document.files ) document.files = [];

                        const entry = document.files.find( (elem: UserFile) => elem.id == JSON.parse(item.value).id);
                        if( !entry ){
                            document.files.push(JSON.parse(item.value));
                        }
                        modifiedCollections.push({type: 'UserFile', value: JSON.parse(item.value), documentId: item.itemid});

                        const currentRafter = await getLocalStorage('currentRafter');
                        if(currentRafter && currentRafter.id == document.id){
                            await setLocalStorage('currentRafter', document);
                        }
                        const currentCustomRafter = await getLocalStorage('currentCustomRafter');
                        if(currentCustomRafter && currentCustomRafter.id == document.id){
                            await setLocalStorage('currentCustomRafter', document);
                        }
                    }
                    else{
                        data.push(JSON.parse(item.value));
                        modifiedCollections.push({type: item.collection, value: JSON.parse(item.value), documentId: item.itemid});

                    }
                } break;
                case 'd':{
                    if(item.collection == 'CheckSheet'){
                        const document = data.find( (elem: any) => elem.id == item.itemid);
                        if(item.field == 'Medications'){
                            const index = document.medications.findIndex( (elem: any) => {
                                return elem.id == item.key
                            });
                            document.medications.splice(index, 1);
                            modifiedCollections.push({type: 'DeleteCheckSheetMedications', value: item.key, documentId: item.itemid});
                        }
                        const currentCheckSheet = await getLocalStorage('currentCheckSheet');
                        if(currentCheckSheet && currentCheckSheet.id == document.id){
                            await setLocalStorage('currentCheckSheet', JSON.parse(JSON.stringify(document)));
                        }
                    }
                    else if( item.collection == 'Rafter' && item.field == 'Files'){
                        const document = data.find( (elem: any) => elem.id == item.itemid);

                        const index = document.files.findIndex( (elem: any) => {
                            return elem.id == JSON.parse(item.value).id
                        });
                        document.files.splice(index, 1);
                        modifiedCollections.push({type: 'DeleteRafterFile', value: JSON.parse(item.value).id, documentId: item.itemid});

                        const currentRafter = await getLocalStorage('currentRafter');
                        if(currentRafter && currentRafter.id == document.id){
                            await setLocalStorage('currentRafter', document);
                        }
                    }
                    else{
                        const index = data.findIndex( (elem: any) => {
                            return elem.id == item.itemid
                        });
                        data.splice(index, 1);
                        modifiedCollections.push({type: item.collection, value: item.itemid, documentId: item.itemid});
                    }
                    
                } break;
                    
            }
            await setLocalStorage(item.collection as string, data);

            const appliedUpdates = await getLocalStorage('syncItems') ?? [];
            appliedUpdates.push(item.id);
            await setLocalStorage('syncItems', appliedUpdates);
            console.log('Updated syncItems with ' + item.id);
        }
        catch(e){
            console.log(e);
            console.log(item);
        }
    }

    return { 
        getOnlineData, 
        queueUpload, 
        startUploading, 
        watchUpdates,
        applyUpdate
    };
}