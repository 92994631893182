import { CheckSheet, CheckSheetTemplate, Rafter, Location, Person, SyncItem } from "@/types";
import { InjectionKey, ref } from "vue";
import type { Ref } from "vue";
import { useStorage } from "./useStorage";

export const GlobalRafterKey = Symbol() as InjectionKey<{
    globalRafter: Ref<Rafter>,
    globalCustomRafter: Ref<Rafter>,
    getGlobalRafter: () => Ref<Rafter>,
    isCustomRafter: () => boolean
}>;
export const GlobalCheckSheetKey = Symbol() as InjectionKey<Ref<CheckSheet | undefined>>;
export const GlobalCheckSheetTemplateKey = Symbol() as InjectionKey<Ref<CheckSheetTemplate>>;
export const GlobalLocationKey = Symbol() as InjectionKey<Ref<Location>>;
export const GlobalUserKey = Symbol() as InjectionKey<Ref<Person>>;
export const GlobalSyncKey = Symbol() as InjectionKey<Ref<SyncItem[]>>;

export async function loadGlobalValues(app: any){
    console.log('loadGlobalValues')
    const { getLocalStorage } = await useStorage();

    const globalUser = ref<Person>(await getLocalStorage('user'));
    app.provide(GlobalUserKey, globalUser);

    const globalCheckSheet = ref<CheckSheet>(await getLocalStorage('currentCheckSheet'));
    app.provide(GlobalCheckSheetKey, globalCheckSheet);

    const globalCheckSheetTemplate = ref<CheckSheetTemplate>(await getLocalStorage('currentCheckSheetTemplate'));
    app.provide(GlobalCheckSheetTemplateKey, globalCheckSheetTemplate);

    const globalLocation = ref<Location>(await getLocalStorage('currentLocation'));
    app.provide(GlobalLocationKey, globalLocation);

    const globalRafter = ref<Rafter>(await getLocalStorage('currentRafter'));
    const globalCustomRafter = ref<Rafter>(await getLocalStorage('currentCustomRafter'));
    console.log('loadGlobalValues/rafter');
    console.log(globalRafter?.value);
    function getGlobalRafter() {
        return globalCustomRafter.value ? globalCustomRafter : globalRafter;
    }
    function isCustomRafter() {
        return globalCustomRafter.value ? true : false;
    }
    app.provide(GlobalRafterKey, { globalRafter, globalCustomRafter, getGlobalRafter, isCustomRafter });

    const globalSync = ref<SyncItem[]>(await getLocalStorage('pendingSync') ?? []);
    app.provide(GlobalSyncKey, globalSync);
}