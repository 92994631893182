import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "menuToggle"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_tab_button = _resolveComponent("ion-tab-button")!
  const _component_ion_tab_bar = _resolveComponent("ion-tab-bar")!
  const _component_ion_tabs = _resolveComponent("ion-tabs")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_tabs, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_router_outlet),
          _createVNode(_component_ion_tab_bar, {
            slot: "bottom",
            class: _normalizeClass('mainRoutes ' + (_ctx.toggleMenu ? 'open' : 'closed'))
          }, {
            default: _withCtx(() => [
              (_ctx.hasRole('60d58e3f7975c685d0e35ca7'))
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createElementVNode("div", {
                      class: "menuToggleButton",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleMenu = !_ctx.toggleMenu)),
                      "aria-label": "Admin"
                    }, [
                      _createVNode(_component_ion_icon, { src: '/assets/icon/i-menu.svg' }),
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode("Admin")
                        ]),
                        _: 1
                      })
                    ])
                  ]))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.routes.filter((x) => _ctx.hasRole(x.meta.role)), (route) => {
                return (_openBlock(), _createBlock(_component_ion_tab_button, {
                  key: route.path,
                  tab: route.path,
                  href: route.path,
                  class: _normalizeClass(route.meta.customClasses),
                  onClick: ($event: any) => (_ctx.checkLinkType(route.path)),
                  disabled: (route.meta.disabledOnNoRafter == true) && !_ctx.currentRafter,
                  "data-after": route.path == '/todo' ? _ctx.getTodoCount() : ''
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      src: '/assets/icon/' + route.meta.icon + '.svg'
                    }, null, 8, ["src"]),
                    (!route.meta.iconOnly)
                      ? (_openBlock(), _createBlock(_component_ion_label, { key: 0 }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(route.name), 1)
                          ]),
                          _: 2
                        }, 1024))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1032, ["tab", "href", "class", "onClick", "disabled", "data-after"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["class"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}