import { Drivers, Storage } from "@ionic/storage";
import { ref } from 'vue';

export async function useStorage() {
    const localStorage = ref<Storage>(new Storage({
        name: '__putyDB',
        driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
    }));

    await localStorage.value.create();

    const setLocalStorage = async (index: string, value: any) => {
      await localStorage.value.set(index, value);
    };
    
    const getLocalStorage = async (index: string) => {
      return await localStorage.value.get(index);
    };

    const removeLocalStorage = async (index: string) => {
      await localStorage.value.remove(index);
    };

    const clearLocalStorage = async () => {
      await localStorage.value.clear();
    };

    return{
      setLocalStorage,
      getLocalStorage,
      removeLocalStorage,
      clearLocalStorage
    }
}