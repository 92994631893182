<template>
    <ion-content>
        <ion-segment mode="ios" :value="currentLocation?.id" @ionChange="dismissModal($event)">
            <ion-segment-button v-for="l in locations" :key="l" :value="l.id">
                <ion-label>{{ l.name }}</ion-label>
            </ion-segment-button>
        </ion-segment>
</ion-content>
</template>

<script lang="ts">
import { IonContent, IonLabel, modalController, IonSegment, IonSegmentButton } from '@ionic/vue';
import { defineComponent, onBeforeMount, ref } from 'vue';
import { Location } from "@/types";
import { useStorage } from '@/composables/useStorage';

export default defineComponent({
    name: 'SwitchLocationModal',
    components: { IonContent, IonLabel, IonSegment, IonSegmentButton },
    props: {
    },
    setup() {
        const locations = ref<Location[]>([]);
        const currentLocation = ref<Location>();

        onBeforeMount(async () => {
            const { getLocalStorage } = await useStorage();
            locations.value = await getLocalStorage('Location');
            currentLocation.value = await getLocalStorage('currentLocation');
        });
        
        return {
            locations, currentLocation
        };
    },
    methods: {
        dismissModal(event: any) {
            if(event.detail.value){
                const newLocation = this.locations.filter( x => x.id == event.detail.value)[0];
                if(newLocation){
                    modalController.dismiss(newLocation);
                }

            }
            modalController.dismiss(this.currentLocation);
        }
    }
});
</script>