
import { toastController } from '@ionic/vue';
import { useStorage } from './useStorage';

export function updateApp() {

    const showUpdateNotification = async (registration: ServiceWorkerRegistration, onlineVersion: string) => {

        const toast = await toastController.create({
            message: "App Aktualisierung verfügbar!",
            duration: 20000,
            buttons: [
                {
                    text: 'Jetzt aktualisieren',
                    handler: () => {
                        registration.unregister();
                        useStorage().then( (value) => {
                            value.setLocalStorage('version', onlineVersion);
                            window.location.reload();
                        });
                    }
                }
            ],
            position: 'top'
        });
        toast.present();
    }

    return { showUpdateNotification };
}