
import { useStorage } from '@/composables/useStorage';
import { CheckSheet, CheckSheetEntry, CheckSheetTemplate, Medication, Rafter, Location, WeeklyTask, Stable, CheckSheetEntryTemplate } from "@/types";
import { ObjectID } from 'bson';
import dayjs from 'dayjs';
import { inject } from 'vue';
import { GlobalCheckSheetKey, GlobalCheckSheetTemplateKey, GlobalRafterKey } from './globals';
import { restClient } from './restClient';

export function checksheetFunctions() {
    const createChecksheet = async (rafter: Rafter) => {
        const { getLocalStorage } = await useStorage();

        const checkSheetTemplates = await getLocalStorage('CheckSheetTemplate');
        const checkSheetTemplate = checkSheetTemplates.filter( (x: CheckSheetTemplate) => x.location == rafter.location)[0];

        const checkSheet = {
            id: new ObjectID().toString(),
            entries: [], 
            medications: [],
            weeklyTasks: [],
            location: rafter.location,
            rafter: rafter.id
        } as CheckSheet;

        // checkSheetTemplate.entries?.forEach((entry: any) => {
        //     const entryDate = dayjs(fromDate).subtract(1, 'day').toDate();
        //     rafter.stables?.forEach(s => {
        //         checkSheet.entries?.push({ key: entry.key, sortOrder: entry.sortOrder, created: entryDate, stable: s.stable} as CheckSheetEntry);
        //     });
        // });

        checkSheet.medications = getMedication(checkSheetTemplate, rafter.stablingDate);
        checkSheet.weeklyTasks = getWeeklyTasks(checkSheetTemplate, rafter.stablingDate);

        return checkSheet;
    };

    const getMedication = ( checkSheetTemplate: CheckSheetTemplate, fromDate: Date ) => {
        const medications: Medication[] = [];

        checkSheetTemplate?.medications?.forEach((medication) => {
            if( medication.medicationDateFromDay !== undefined ){
                medication.medicationDateNominal = dayjs(fromDate).add((medication.medicationDateFromDay as number)-1, 'days').toDate();
            }
            medications.push( medication );
        });

        return medications;
    };

    const getWeeklyTasks = ( checkSheetTemplate: CheckSheetTemplate, stablingDate: Date ) => {
        const weeklyTasks: WeeklyTask[] = [];

        checkSheetTemplate?.weeklyTasks?.forEach((task) => {
            task.id = new ObjectID().toString();
            task.created = stablingDate;
            weeklyTasks.push( task );
        });

        return weeklyTasks;
    };

    const getHistoricalTotalCount = (currentRafter: Rafter, checkSheet: CheckSheet, date: Date, stableId = "") => {
        const ret: { name: string|undefined; count: number; gender: string }[] = [];
        currentRafter.stables?.filter(s => stableId.length == 0 || stableId == s.stable).forEach( (stable) => {
            const values = checkSheet?.entries?.filter( obj => obj.stable == stable.stable && obj.key?.startsWith('Verlust') && dayjs(obj.created).isBefore(date, 'days'));
            let count = stable.gender == 0 ? currentRafter.quantityM : currentRafter.quantityF;
            values?.forEach( (entry: CheckSheetEntry) => {
                if( entry.value && entry.value.length > 0 ){
                    count -= +(entry.value as string);
                }
            });
            ret.push({
                name: stable.name,
                count: count,
                gender: stable.gender ? 'Hennen' : 'Hahnen'
            });

        });
        return ret;
    }

    const findRafter = async (currentLocation: Location) => {
        const { getLocalStorage, setLocalStorage } = await useStorage();
        let currentRafter: Rafter;
        const rafters = await getLocalStorage('Rafter');
        try{    
            // const customRafter = await getLocalStorage('currentCustomRafter');
            // if(customRafter){
            //     currentRafter = rafters.find( (elem: Rafter) => elem.id == customRafter);
            //     await setLocalStorage('currentRafter', currentRafter);
            // }
            // else{
                currentRafter = rafters.find( (elem: Rafter) => elem.location == currentLocation.id && dayjs(elem.stablingDate).subtract(2, 'days').format('YYYYMMDD') <= dayjs().format('YYYYMMDD') && dayjs(elem.restablingDate).add(1, 'day').format('YYYYMMDD') >= dayjs().format('YYYYMMDD'));
                if(!currentRafter){
                    const { findCurrentRafter } = restClient();
                    currentRafter = await findCurrentRafter(currentLocation.id as string);
                }
                await setLocalStorage('currentRafter', currentRafter);
            // }
            
        }
        catch(e){
            console.log('catch');
            const { findCurrentRafter } = restClient();
            currentRafter = await findCurrentRafter(currentLocation?.id as string);
            await setLocalStorage('currentRafter', currentRafter);
        }
        if( !currentRafter ){
            await setLocalStorage('currentCheckSheet', undefined);
        }

        return currentRafter;
    }
    const findExistingCheckSheet = async (currentRafter: Rafter) =>{
        const { getLocalStorage, setLocalStorage } = await useStorage();
        const checkSheets = await getLocalStorage('CheckSheet');
        let checkSheet: CheckSheet;
        try{   
            const customRafter = await getLocalStorage('currentCustomRafter');
            if(customRafter){
                checkSheet = checkSheets.find( (elem: CheckSheet) => elem.rafter == customRafter.id);
            } 
            else{
                checkSheet = checkSheets.find( (elem: CheckSheet) => elem.rafter == currentRafter?.id);
                if(!checkSheet){
                    if(!checkSheet){
                        const { findCurrentChecksheet } = restClient();
                        checkSheet = await findCurrentChecksheet(currentRafter?.id as string);
                    }
                }
            }
            await setLocalStorage('currentCheckSheet', JSON.parse(JSON.stringify(checkSheet)));

        }
        catch(e){
            const { findCurrentChecksheet } = restClient();
            checkSheet = await findCurrentChecksheet(currentRafter?.id as string);
            await setLocalStorage('currentCheckSheet', JSON.parse(JSON.stringify(checkSheet)));
        }

        return checkSheet;
    };
    
    const findHistoricCheckSheet = async (rafter: Rafter) =>{
        const { getLocalStorage, setLocalStorage } = await useStorage();
        const checkSheets = await getLocalStorage('CheckSheet');
        try{   
            const historicCheckSheet = checkSheets.find( (elem: CheckSheet) => elem.rafter == rafter?.id);
            return historicCheckSheet;
        }
        catch(e){
            console.log(e);
        }

        return null;
    };
    const findChecksheetTemplate = async (currentLocation: Location) => {
        const { getLocalStorage, setLocalStorage } = await useStorage();
        const templates = await getLocalStorage('CheckSheetTemplate');
        try{    
            const template = templates.find( (elem: CheckSheetTemplate) => elem.location == currentLocation?.id as string);
            await setLocalStorage('currentCheckSheetTemplate', template);
        }
        catch(e){
            const { findChecksheetTemplateFromLocation } = restClient();
            const template = await findChecksheetTemplateFromLocation(currentLocation?.id as string);
            await setLocalStorage('currentCheckSheetTemplate', template);
        }
    };
    const checkNewChecksheetEntries = async (checksheetId: string, date: Date) => {

        const { checkNewChecksheetEntries } = restClient();
        return await checkNewChecksheetEntries(checksheetId, date);
    };
    const getExistingChecksheetEntries = async (checksheetId: string, date: Date) => {

        const { getExistingChecksheetEntries } = restClient();
        return await getExistingChecksheetEntries(checksheetId, date);
    };
    const getOrderedKeys = (checkSheet: CheckSheet, stableId: string) => {
        if(checkSheet.entries !== undefined && checkSheet.entries.length > 0){
            const day = dayjs(checkSheet.entries[0].created).format('YYYYMMDD');

            return checkSheet.entries.filter( (item) => item.created !== undefined && dayjs(item.created).format('YYYYMMDD') == day && item.stable == stableId).sort( item => item.sortOrder).map( (item) => ({
                "key": item.key
            }));
        }
        return null;
    };

    const getChecksheetTemplateSettings = async (checkSheet: CheckSheet) => {
        const { getLocalStorage } = await useStorage();
        const template = await getLocalStorage('currentCheckSheetTemplate');

        template.entries?.forEach((entry: CheckSheetEntryTemplate) => {
            const values = checkSheet.entries?.filter( (obj: CheckSheetEntry) => obj.key == entry.key );
            values?.forEach( (value: CheckSheetEntry) => {
                value.unit = entry.unit;
                value.unifiedValue = entry.unifiedValue;
                value.sortOrder = entry.sortOrder;
            });
        });
    };

    const sortChecksheetMedications = (checkSheet: CheckSheet | undefined) => {
        checkSheet?.medications?.sort( (a:Medication, b:Medication) => {
            return dayjs(a.medicationDateNominal).isSame(dayjs(b.medicationDateNominal), 'day') ? (a.sortOrder < b.sortOrder ? -1 : 1) : (dayjs(a.medicationDateNominal).isBefore(dayjs(b.medicationDateNominal)) ? -1 : 1)
        });
    }

    return{
      createChecksheet,
      getMedication,
      getHistoricalTotalCount,
      findRafter,
      findExistingCheckSheet,
      findHistoricCheckSheet,
      findChecksheetTemplate,
      checkNewChecksheetEntries,
      getExistingChecksheetEntries,
      getOrderedKeys,
      getChecksheetTemplateSettings,
      sortChecksheetMedications
    }
}