import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_progress_bar = _resolveComponent("ion-progress-bar")!
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_IonApp = _resolveComponent("IonApp")!

  return (_openBlock(), _createBlock(_component_IonApp, {
    class: _normalizeClass(_ctx.route.meta.pageClass + ' ' + (_ctx.isLoading ? 'loading' : '')),
    id: "appRef"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_progress_bar, { type: "indeterminate" }),
      (_openBlock(), _createBlock(_component_ion_router_outlet, {
        id: "main-content",
        key: _ctx.routerKey
      }))
    ]),
    _: 1
  }, 8, ["class"]))
}