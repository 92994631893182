<template>
    <IonApp :class="route.meta.pageClass + ' ' + (isLoading ? 'loading' : '')" id="appRef">
        <ion-progress-bar type="indeterminate"></ion-progress-bar>
        <ion-router-outlet id="main-content" :key="routerKey"></ion-router-outlet>
        <!-- <div id="consoleOutput" @click="consoleOutput=''">
            <p v-html="consoleOutput"></p>
        </div> -->
    </IonApp>
</template>

<script lang="ts">
import { IonApp, IonProgressBar, IonRouterOutlet, toastController } from '@ionic/vue';
import { defineComponent, inject, onBeforeMount, onMounted, provide, Ref, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { documentOutline, documentSharp, homeOutline, homeSharp, location, locationSharp, people, peopleSharp, star, starSharp, peopleCircle, peopleCircleSharp, sunny, sunnySharp, gitNetwork, gitNetworkSharp, hourglassOutline, hourglassSharp, chatbubbles, chatbubblesSharp, apps, appsSharp, scale, scaleSharp, archiveOutline, archiveSharp, bookmarkOutline, bookmarkSharp, caretDownOutline, caretUpOutline, egg, eggSharp, heartOutline, heartSharp, logInOutline, logInSharp, clipboardOutline, clipboardSharp, settingsOutline, trashOutline, trashSharp, warningOutline, warningSharp } from 'ionicons/icons';
import { useStorage } from "@/composables/useStorage";
import { Person, Location, SyncItem, CheckSheetEntry, Medication, UserFile } from "@/types";
import dayjs from 'dayjs';
import { syncData } from './composables/syncData';
import { GlobalCheckSheetKey, GlobalLocationKey, GlobalRafterKey, GlobalSyncKey } from './composables/globals';
import { checksheetFunctions } from './composables/checksheetFunctions';

export default defineComponent({
  name: 'App',
  components: {
    IonApp, IonRouterOutlet, IonProgressBar
  },
  setup() {
    const currentUser = ref<Person>({});
    const currentLocation = ref<Location>();
    const currentMode = ref("pute");
    const currentDate = ref<string>(new Date().toString());
    const selectedIndex = ref(0);
    const menuSwipe = ref(false);
    const pulicPath = process.env.BASE_URL;
    const isLoading = ref(false);
    const modifiedCollections = ref<any[]>([]);
    const consoleOutput = ref("");
    const globalSync = inject(GlobalSyncKey);
    const globalCheckSheet = inject(GlobalCheckSheetKey);
    const globalLocation = inject(GlobalLocationKey);
    const GlobalRafter = inject(GlobalRafterKey);
    const globalRafter = GlobalRafter?.getGlobalRafter();

    provide('isLoading', isLoading);
    provide('modifiedCollections', {
        modifiedCollections
    });
    
    const appPagesStrom = ref([
      // {
      //   title: 'Stammdaten',
      //   subtitle: 'Programmverwaltung',
      //   url: '/stammdaten/eiererzeuger',
      //   icon: 'i-data',
      //   isParent: false,
      //   isOpen: true,
      //   role: '60d58e3f7975c685d0e35ca7',
      //   children: [
      //       {
      //           category: 'Benutzerverwaltung',
      //           title: 'Benutzer',
      //           url: '/stammdaten/benutzer',
      //           icon: 'i-users'
      //       },
      //       {
      //           title: 'Rollen',
      //           url: '/stammdaten/rollen',
      //           icon: 'i-roles'
      //       },
      //       {
      //           title: 'Zeitaufzeichnung',
      //           url: '/stammdaten/zeitaufzeichnung',
      //           icon: 'i-planning'
      //       }
      //   ]
      // },
      {
        title: 'Stall',
        subtitle: 'Wo bin ich? Stall ändern.',
        url: '/stall-aendern',
        icon: 'i-switch',
        iconOnly: true,
        isOpen: false,
        isParent: false,
        customClasses: 'menu-spacer',
        getStable: true,
        role: '60d590527975c685d0e35ca860d58e3f7975c685d0e35ca7'
      },
      {
          title: 'Ausloggen',
          subtitle: 'Mein Benutzerkonto',
          url: '/',
          icon: 'i-log',
          iconOnly: true,
          isParent: false,
          isOpen: false,
          role: ''
      },
    ]);

    const route = useRoute();
    const router = useRouter();
    
    const checkDate = async () => {
        if( !dayjs(currentDate.value).isToday() ){
            currentDate.value = new Date().toString();
            const { setLocalStorage } = await useStorage();
            await setLocalStorage('currentDate', currentDate.value);
            window.location.reload();
        }
    }
    checkDate();
    setInterval(checkDate, 10000);

    // const oldLog = console.log;
    // console.log = function(message) {
    //     consoleOutput.value += JSON.stringify(message) + '<br />';
    // };

    onBeforeMount(async () => {
        const { getLocalStorage, setLocalStorage } = await useStorage();
        const { startUploading, watchUpdates, getOnlineData, applyUpdate } = await syncData();
        if( !await getLocalStorage('dbInitialized')){
            isLoading.value = true;

            const toast = await toastController.create({
                message: "Datenbank wird aktualisiert ...",
                position: 'top'
            });
            toast.present();

            await getOnlineData('Address');
            await getOnlineData('Breed');
            await getOnlineData('CheckSheet');
            await getOnlineData('CheckSheetTemplate');
            await getOnlineData('EggProducer');
            // await getOnlineData('File');
            await getOnlineData('FileTag');
            await getOnlineData('Hatchery');
            await getOnlineData('Location');
            await getOnlineData('Medication');
            await getOnlineData('Person');
            await getOnlineData('QualityStandard');
            await getOnlineData('Rafter');
            await getOnlineData('Role');
            await getOnlineData('Stable');
            await getOnlineData('Zeitaufzeichnung/template');
            await getOnlineData('Zeitaufzeichnung');

            await setLocalStorage('dbInitialized', true);
            await setLocalStorage('lastSyncCheck', new Date());

            toast.dismiss();

            isLoading.value = false;
            window.location.reload();
        }

        startUploading();

        if( globalSync ){
            watchUpdates( modifiedCollections.value, globalSync.value );
        }
        


        currentUser.value = await getLocalStorage('user');
        currentLocation.value = await getLocalStorage('currentLocation');
        currentMode.value = await getLocalStorage("currentMode");

        if( currentMode.value === undefined || currentMode.value === null ){
            currentMode.value = 'pute';
            await setLocalStorage('currentMode', currentMode.value);
        }

        const customDate = await getLocalStorage('currentDate');
        if( customDate == null || customDate == "" || dayjs(currentDate.value).format('YYYY') == "2021"){
            await setLocalStorage('currentDate', dayjs(currentDate.value).format('YYYY-MM-DD'));
        }
        else{
            await setLocalStorage('currentDate', currentDate.value);
        }
    });

    watch(modifiedCollections.value, (newValue, oldValue) => {
        console.log('watch update');

        newValue.forEach( (item:any, i:number) => {
            if(item.type == 'CheckSheetEntry' && globalCheckSheet?.value?.id == item.documentId){
                const entry = globalCheckSheet?.value?.entries?.find( (elem: CheckSheetEntry) => elem.key == item.value.key && elem.stable == item.value.stable && dayjs(elem.created).isSame(item.value.created, 'day'));
                if(entry){
                    entry.value = item.value.value;
                    entry.person = item.value.person;
                    entry.modified = item.value.modtime;
                    console.log('CheckSheetEntry updated');
                }
            }
            else if(item.type == "Location" && globalLocation && globalLocation.value.id == item.documentId){
                globalLocation.value == item.value;
                console.log('GlobalLocation updated');
            }
            else if(item.type == "Medications" && globalCheckSheet?.value && globalCheckSheet?.value?.id == item.documentId && globalCheckSheet.value.medications){
                const index = globalCheckSheet.value.medications?.findIndex( (elem: Medication) => elem.id == item.value.id);
                if( index > -1 ){
                    if(globalCheckSheet.value.medications[index].customMedication){
                        globalCheckSheet.value.medications[index] = item.value;
                    }
                    else{
                        globalCheckSheet.value.medications[index].medicationDoses = item.value.medicationDoses;
                    }
                }
                else{
                    globalCheckSheet?.value?.medications?.push(item.value);
                    const { sortChecksheetMedications } = checksheetFunctions();
                    sortChecksheetMedications(globalCheckSheet?.value);
                }
                console.log('GlobalMedications updated');
            }
            else if( item.type == 'DeleteCheckSheetMedications' && globalCheckSheet?.value?.id && globalCheckSheet.value.id == item.documentId && globalCheckSheet.value.medications){
                const index = globalCheckSheet.value.medications.findIndex( (elem: any) => {
                    return elem.id == item.value
                });
                if( index > -1){
                    globalCheckSheet.value.medications.splice(index, 1);
                    console.log('DeleteCheckSheetMedications updated');
                }
            }
            else if(item.type == 'UserFile' && globalRafter?.value?.files && globalRafter.value.id == item.documentId){
                console.log('watch UserFile');
                const entry = globalRafter.value.files.find( (elem: UserFile) => elem.id == item.value.id);
                console.log('entry');
                if(entry){
                    entry.name = item.value.name;
                    entry.comment = item.value.comment;
                    entry.tags = item.value.tags;
                    console.log('UserFile updated');
                }
                else{
                    globalRafter.value.files.push(item.value);
                }
            }
            else if(item.type == 'DeleteRafterFile' && globalRafter?.value?.files && globalRafter.value.id == item.documentId){
                const index = globalRafter.value.files.findIndex( (elem: any) => {
                    return elem.id == item.value
                });
                if( index > -1){
                    globalRafter.value.files.splice(index, 1);
                }
            }
            modifiedCollections.value.splice(i, 1);
        });
    });



    return { 
      selectedIndex,
      documentOutline, documentSharp, homeOutline, homeSharp, location, locationSharp, people, peopleSharp, star, starSharp, peopleCircle, peopleCircleSharp, sunny, sunnySharp, gitNetwork, gitNetworkSharp, hourglassOutline, hourglassSharp, chatbubbles, chatbubblesSharp, apps, appsSharp, scale, scaleSharp, archiveOutline, archiveSharp, bookmarkOutline, bookmarkSharp, caretDownOutline, caretUpOutline, egg, eggSharp, heartOutline, heartSharp, logInOutline, logInSharp, clipboardOutline, clipboardSharp, settingsOutline, trashOutline, trashSharp, warningOutline, warningSharp,
      currentUser,
      currentLocation,
      currentDate,
      route,
      router,
      pulicPath,
      currentMode,
      isLoading,
      routerKey: inject<number>('routerKey'),
      menuSwipe,consoleOutput
    }
  },
  methods: {
      async onDateChange(){
          const { setLocalStorage } = await useStorage();
          await setLocalStorage('currentDate', this.currentDate);
      },
      hasRole(role: string){
          if(role.length == 0) return true;
          const find = this.currentUser?.roles?.find( r => {
            return role.indexOf(r as string) > -1;
          });
          return find !== undefined;
      }
  }
});
</script>

<style scoped>

.menu_child_item ion-item ion-icon{
    padding-left: 40px;
}

ion-menu ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}

ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu.md ion-list-header,
ion-menu.md ion-note {
  padding-left: 10px;
}

ion-menu.md ion-list#inbox-list ion-list-header {
  font-size: 22px;
  font-weight: 600;

  min-height: 20px;
}

ion-menu.md ion-list#labels-list ion-list-header {
  font-size: 16px;

  margin-bottom: 18px;

  color: #757575;

  min-height: 26px;
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
  color: #616e7e;
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios ion-list#labels-list ion-list-header {
  margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-left: 16px;
  padding-right: 16px;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}

ion-note {
  display: inline-block;
  font-size: 16px;

  color: var(--ion-color-medium-shade);
}

ion-item.selected {
  --color: var(--ion-color-primary);
}

ion-item{
    cursor: pointer;
}
</style>