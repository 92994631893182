/* eslint-disable no-console */

import { register } from 'register-service-worker'
import { restClient } from './composables/restClient';
import { useStorage } from './composables/useStorage';
import { updateApp } from './composables/updateApp';


if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'development') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker..\n' +
        'For more details, visit https://goo.gl/AFskqB'
      );
    },
    async registered (registration) {
        console.log('Service worker has been registered.');

        const { getVersion, getOption } = restClient();
        const { getLocalStorage, setLocalStorage } = await useStorage();
        const onlineVersion = await getVersion();
        const requireDbUpdate = await getOption("require-db-update");
        let localVersion = await getLocalStorage('version');
        const localDbUpdate = await getLocalStorage('localDbUpdate');

        if( !localVersion ){
            localVersion = onlineVersion;
            await setLocalStorage('version', localVersion);
        }
        else if( await getLocalStorage('dbInitialized') && (!onlineVersion || +onlineVersion > +localVersion) ){
            const { showUpdateNotification } = updateApp();
            await showUpdateNotification(registration, onlineVersion);
        }
        else if(localVersion == requireDbUpdate && (!localDbUpdate || +localVersion > +localDbUpdate)){
            await setLocalStorage('localDbUpdate', localVersion);
            await setLocalStorage('dbInitialized', false);
            window.location.pathname = '/login';
        }
    },
    cached () {
      console.log('Content has been cached for offline use...');
    },
    updatefound () {
      console.log('New content is downloading.');
    },
    updated(registration) {
        console.log('New content is available; please refresh.');
        document.dispatchEvent(
          new CustomEvent('swUpdated', { detail: registration })
        );
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.');
    },
    error (error) {
      console.error('Error during service worker registration:', error);
    }
  });
}
